* {
  box-sizing: border-box;
  /* iOS Safari specific */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: url('background.png') repeat;
}